import React, { FC, useState, useEffect } from "react";

export interface BtnCreateursVoyageIconProps {
  className?: string;
  colorClass?: string;
  onClick?: () => void; 
}

const BtnCreateursVoyageIcon: FC<BtnCreateursVoyageIconProps> = ({
  className = "",
  colorClass = "text-white",
  onClick
}) => {

  return (
    <div
      className={`nc-voyagBtnCreateursVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnCreateursVoyageIcon"
      title="Co-créateurs"
      onClick={onClick}
    ><i className={`p-1 text-xl las la-users`}></i>
    </div>
  );
};

export default BtnCreateursVoyageIcon;
