import React, { FC, useState, useEffect } from "react";

export interface BtnPDFIconProps {
  className?: string;
  colorClass?: string;
  onClick?: () => void; 
}

const BtnPDFIcon: FC<BtnPDFIconProps> = ({
  className = "",
  colorClass = "text-white",
  onClick
}) => {

  return (
    <div
      className={`nc-voyagBtnPDFVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnPDFIcon"
      title="Editer en PDF"
      onClick={onClick}
    ><i className={`p-1 text-xl la la-file-pdf-o`}></i>
    </div>
  );
};

export default BtnPDFIcon;
