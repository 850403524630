import React, { FC, useState, useEffect } from "react";

export interface BtnCopieIconProps {
  className?: string;
  colorClass?: string;
  onClick?: () => void; 
}

const BtnCopieIcon: FC<BtnCopieIconProps> = ({
  className = "",
  colorClass = "text-white",
  onClick
}) => {

  return (
    <div
      className={`nc-voyagBtnCopieVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnCopieIcon"
      title="Copier"
      onClick={onClick}
    ><i className={`p-1 text-xl las la-copy`}></i>
    </div>
  );
};

export default BtnCopieIcon;
