import React, { FC, useState, useEffect } from "react";

export interface BtnPartageRSIconProps {
  className?: string;
  colorClass?: string;
  onClick?: () => void; 
}

const BtnPartageRSIcon: FC<BtnPartageRSIconProps> = ({
  className = "",
  colorClass = "text-white",
  onClick
}) => {

  return (
    <div
      className={`nc-voyagBtnPartageRSVoyageIcon flex items-center justify-center rounded-full cursor-pointer ${colorClass} ${className} `}
      data-nc-id="BtnPartageRSIcon"
      title="Partager"
      onClick={onClick}
    ><i className={`p-1 text-xl las la-share-alt`}></i>
    </div>
  );
};

export default BtnPartageRSIcon;
