import Label from "components/Label/Label";
import React, { FC, useEffect, useState, useRef, useContext } from "react";
import Avatar from "shared/Avatar/Avatar";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { compteService } from "_services/compte.service";
import { useNavigate } from "react-router-dom";
import CheckboxTripkygo from "shared/CheckboxTripkygo/CheckboxTripkygo";
import AppContext from 'AppContext';
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const [editedProfile, setEditedProfile] = useState<any>({});
  const [profilData, setProfilData] = useState<any>(null);
  const [newsletterChecked, setNewsletterChecked] = useState<boolean>(false);
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true);
  const [isUsernamePris, setIsUsernamePris] = useState<boolean>(true);
  const usernameInputRef = useRef<HTMLInputElement>(null); // Création de la référence
  const usernameInputSiteRef = useRef<HTMLInputElement>(null); 
  const usernameInputInstaRef = useRef<HTMLInputElement>(null); 
  const usernameInputFbRef = useRef<HTMLInputElement>(null); 
  const usernameInputXRef = useRef<HTMLInputElement>(null); 
  const usernameInputYoutubeRef = useRef<HTMLInputElement>(null); 
  const [alertShown, setAlertShown] = useState(false);
  const [imageAvatar, setImageAvatar] = useState("");
 
  let navigate=useNavigate();

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
    setIsUsernamePris(false);

    // Appeler l'API pour obtenir les informations sur le voyageur
    compteService.getProfil()
      .then((response) => {
        setProfilData(response.data[0]);
        setNewsletterChecked(response.data[0]?.newsletter === 'O');
      })
      .catch((error: any) => {

        console.error("Error fetching data:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
          navigate(t('lienLogin'));
        }
        else
        {
          alert(t('alertRecupProfil'));
          localStorage.removeItem('xsrfToken');
          navigate(t('lienHome'));
        }
      });
  }, []);

  useEffect(() => {
   
    let imageURL: string | undefined;
  
     if(profilData?.avatar && profilData?.avatar !== "")
     {

       Axios.get(profilData?.avatar.url, { responseType: 'blob' })
       .then((response) => {

         const blob = new Blob([response.data], { type: 'image/jpeg' });
         imageURL = URL.createObjectURL(blob);
         setImageAvatar(imageURL);
       })
       .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
     }
  
     return () => {
       if (imageURL) {
         URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
       }
     };
     
   }, [profilData?.avatar]);


  const handleFieldChange = (fieldName: string, value: string | boolean) => {

    if(typeof value === 'string')
    {
      value=value.trim();
    }
    setEditedProfile((prevProfile: any) => ({
      ...prevProfile,
      [fieldName]: value,
    }));
  
    if (fieldName === 'newsletter') {
      handleFieldBlurNewsletter('newsletter', value);
    }
  };
  
  const handleFieldBlurNewsletter = (fieldName: string, value: string | boolean) => {
    saveChanges(fieldName, value);
    setNewsletterChecked(value as boolean);
  };

  const handleFieldBlur = (fieldName: string) => {
    saveChanges(fieldName, newsletterChecked);
    setAlertShown(false);
  };

  const handleFieldRSBlur = async (fieldName: string, value: string) => {
    setAlertShown(false);
    let usernamePattern;

    if(fieldName === 'site')
    {
      usernamePattern = /^[^\s]*$/; 
    }
    else
    {
      usernamePattern = /^[^\s@]*$/; 
    }
  
    const isValid = usernamePattern.test(value.trim());

    if (isValid) {
      try {

        await saveChanges(fieldName, newsletterChecked);
        setAlertShown(false);

      } catch (error) {
        console.error("Erreur lors de la sauvegarde des modifications:", error);
      }
    } else {
        if(!alertShown)
        {
          setAlertShown(true);
          if(fieldName === 'site')
          {
            alert(
              t('alertChampSiteInvalide')
            );
            if(usernameInputSiteRef.current !== null)
            {
              usernameInputSiteRef.current.focus();
            }
          }
          else
          {
            alert(
              t('alertChampRSInvalide')
            );
            if(fieldName === 'instagram')
            {
              if(usernameInputInstaRef.current !== null)
              {
                usernameInputInstaRef.current.focus();
              }
            }
            else if(fieldName === 'facebook')
            {
              if(usernameInputFbRef.current !== null)
              {
                usernameInputFbRef.current.focus();
              }
            }
            else if(fieldName === 'youtube')
            {
              if(usernameInputYoutubeRef.current !== null)
              {
                usernameInputYoutubeRef.current.focus();
              }
            }
            else if(fieldName === 'twitter')
            {
              if(usernameInputXRef.current !== null)
              {
                usernameInputXRef.current.focus();
              }
            }
          }
        }
    }
 };

  const handleUsernameBlur = async (value: string) => {
    // Vérifiez si la valeur actuelle correspond au motif souhaité (ajustez le motif en conséquence)
    const usernamePattern = /^[a-zA-Z][a-zA-Z0-9-_.]{4,20}$/;
    const isValid = usernamePattern.test(value);
  
    // Mettez à jour l'état du motif
    setIsUsernameValid(isValid);

    if (isValid) {
      try {

        setIsUsernamePris(false);
        await saveChanges("username", value); // Attendre la résolution de la promesse
      } catch (error) {
        console.error("Erreur lors de la sauvegarde des modifications:", error);
      }
    } else {

        if(!alertShown)
        {
          setAlertShown(true);
          alert(
            t('alertUsernameInvalide')
          );
          if(usernameInputRef.current !== null)
          {
            usernameInputRef.current.focus();
          }
        }
         // Retraut du `username` de l'objet `editedProfile`
          setEditedProfile((prevProfile: any) => {
            const { username, ...rest } = prevProfile;
            return rest;
          });

    }
  };
  


   // Fonction pour sauvegarder les modifications
const saveChanges = (champModifie: any, checked:  string | boolean) => {

  let updatedProfile='';
  if(champModifie === 'newsletter')
  {
    updatedProfile = {
      ...editedProfile,
      newsletter: checked ? 'O' : '',
    };
  }
  else
  {
    updatedProfile = {
      ...editedProfile
    };
  }

  // Envoyer une requête PUT avec les données modifiées
  compteService
    .updateProfil(updatedProfile)
    .then((response: any) => {

      // Réinitialiser l'état local des modifications
      setEditedProfile({});
    })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        alert(t('alertEnregProfil'));
        localStorage.removeItem('xsrfToken');
        navigate(t('lienLogin'));
      }
      else if (error.response?.status === 406) {
        if(usernameInputRef.current !== null)
        {
          setIsUsernamePris(true);
          usernameInputRef.current.focus();
        }
      }
      else
      {
        alert(t('alertEnregProfil'));
      }
      console.error('Error maj profil:', error);
    });
};

const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const fileInput = event.target;
  const file = fileInput.files?.[0];

  if (file) {
    // Check file type
    if (!/\/(jpeg|jpg|png)$/.test(file.type)) {
      alert(t('alertFormatFichier'));
      fileInput.value = ""; // Clear the file input
      return;
    }

    // Check file size
    const maxSize = 7 * 1024 * 1024; // 7MB
    if (file.size > maxSize) {
      alert(t('alertTailleFichier'));
      fileInput.value = ""; // Clear the file input
      return;
    }

    // Assuming you have some way to get the user's identifiant
    const fileName = `avatar_${profilData?.identifiant}.${file.type.split("/")[1]}`;

    // Handle file upload to the server or save it locally
    try {
      // Example using the Fetch API to upload the file (modify as needed)
      const formData = new FormData();
      formData.append("avatar", file, fileName);

      const xsrfToken = localStorage.getItem("xsrfToken");

      compteService.uploadAvatar(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    .then((response: any) => {


      const updatedProfilData = {
        ...profilData,
        avatar: response.data.avatarInfo,  // Update this line based on your API response structure
      };

      // Update the state with the new profile data
      setProfilData(updatedProfilData);

      // Réinitialiser l'état local des modifications
      setEditedProfile({});
    })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        alert(t('alertEnregPhoto'))
        localStorage.removeItem('xsrfToken');
        navigate(t('lienLogin'));
      }
      else if (error.response?.status === 406) {
        if(usernameInputRef.current !== null)
        {
          setIsUsernamePris(true);
          usernameInputRef.current.focus();
        }
      }
      else
      {
        alert(t('alertEnregPhoto'));
      }
      console.error('Error maj image:', error);
    });

      // Optionally, update the user's profile data with the new avatar filename
      //await saveChanges("avatar", fileName);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(t('alertEnregPhoto'));
    }
  }
};


  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{t('compte')}</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t('profil')}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" 
                imgUrl={`${imageAvatar && imageAvatar !== ''
                ? imageAvatar
                : ''}`}
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">{t('changerImage')}</span>
                </div>
                <form encType="multipart/form-data">
                <input
                  name='avatar'
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={handleFileChange}
                />
                </form>
              </div>   
              {profilData?.username && (
              <div className="absolute lienProfil ml-3 md:ml-0 text-xxs md:text-xs">
                <a href={`${t('lienVoyageur')}/${profilData?.identifiant || 0}`}
                target="_blank" rel="noreferrer">
                  <u>{t('voirProfil')}</u>
                </a>
              </div>       
              )} 
    
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
               { profilData && profilData.mailKo && profilData.mailKo === 'X' && (
                  <div className="texteGras">
                    <p><i className='las la la-warning'></i>{t('messageEMailInvalide1')}<a href={t('lienCompte')}><u>{t('compteMin')}</u></a>{t('messageEMailInvalide2')}<a href={t('lienContact')}><u>{t('messageEMailInvalide3')}</u></a>{t('messageEMailInvalide4')}</p>
                  </div>
                )}
                <div>
                <Label>Email</Label>
                <Input readOnly 
                className="mt-1.5"
                defaultValue={profilData?.email || ''}
                onClick={() => alert("Pour changer votre email de connexion, merci de nous contacter.")}
                style={{ backgroundColor: '#f2f2f2' }}
                 />
              </div>
              <div>
                <CheckboxTripkygo label={t('accepteNewsletter')} name="Wifi" 
                checked={newsletterChecked}
                onChange={(checked) => {
                  handleFieldChange('newsletter', checked);
                }}
                />
              </div>
              <div>
                <Label>{t('nomUtilisateur')}</Label>
                <Input size={20} maxLength={20} className="mt-1.5" 
                defaultValue={profilData?.username || ''} 
                onChange={(e) => handleFieldChange('username', e.target.value)}
                onBlur={(e) => handleUsernameBlur(e.target.value)} 
                ref={usernameInputRef} 
                />
                {!isUsernameValid && (
                  <div className="text-red-500">{t('usernameInvalide')}</div>
                )}
                {isUsernamePris && (
                  <div className="text-red-500">{t('usernameDejaPris')}</div>
                )}
              </div>
              <div>
                <h3 className="font-medium">{t('champsFacultatifs')}</h3>
              </div>
              <div>
                <Label>{t('prenom')} {t('nonVisible')}</Label>
                <Input size={50} maxLength={50} className="mt-1.5" defaultValue={profilData?.prenom || ''} 
                onChange={(e) => handleFieldChange('prenom', e.target.value)}
                onBlur={() => handleFieldBlur('prenom')}   
                />
              </div>
              <div>
                <Label>{t('nom')} {t('nonVisible')}</Label>
                <Input size={50} maxLength={50} className="mt-1.5" 
                defaultValue={profilData?.nom || ''}
                onChange={(e) => handleFieldChange('nom', e.target.value)}
                onBlur={() => handleFieldBlur('nom')} 
               
                />
              </div>
              <div>
                <Label>{t('presentation')}</Label>
                <Textarea maxLength={200} className="mt-1.5" defaultValue={profilData?.presentation || ''} 
                placeholder={t('infoPresentation')}
                onChange={(e) => handleFieldChange('presentation', e.target.value)}
                onBlur={() => handleFieldBlur('presentation')} 
                />
              </div>
            
              <div>
                <Label>{t('sitePersonnel')}</Label>
                <Input size={50} maxLength={50} className="mt-1.5" defaultValue={profilData?.site || ''} 
                onChange={(e) => handleFieldChange('site', e.target.value)}
                onBlur={(e) => handleFieldRSBlur('site', e.target.value)} 
                ref={usernameInputSiteRef} 
                 />
              </div>
              {/* ---- */}
              <div>
                <Label>{t('nomUtilisateur')} Instagram</Label>
                <Input size={50} maxLength={50} className="mt-1.5" defaultValue={profilData?.instagram || ''} 
                onChange={(e) => handleFieldChange('instagram', e.target.value)}
                onBlur={(e) => handleFieldRSBlur('instagram', e.target.value)} 
                ref={usernameInputInstaRef} 
                />
              </div>
              <div>
                <Label>{t('nomUtilisateur')} Facebook</Label>
                <Input size={50} maxLength={50} className="mt-1.5" defaultValue={profilData?.facebook || ''} 
                onChange={(e) => handleFieldChange('facebook', e.target.value)}
                onBlur={(e) => handleFieldRSBlur('facebook', e.target.value)}  
                ref={usernameInputFbRef} 
                />
              </div>
              <div>
                <Label>{t('nomUtilisateur')} Youtube</Label>
                <Input size={100} maxLength={100} className="mt-1.5" defaultValue={profilData?.youtube || ''}
                onChange={(e) => handleFieldChange('youtube', e.target.value)}
                onBlur={(e) => handleFieldRSBlur('youtube', e.target.value)} 
                ref={usernameInputYoutubeRef} 
                />
              </div>
              <div>
                <Label>{t('nomUtilisateur')} X (Twitter)</Label>
                <Input size={50} maxLength={50} className="mt-1.5" defaultValue={profilData?.twitter || ''} 
                onChange={(e) => handleFieldChange('twitter', e.target.value)}
                onBlur={(e) => handleFieldRSBlur('twitter', e.target.value)} 
                ref={usernameInputXRef} 
                />
              </div>
              <div>
                {t('compteCreeLe')} {profilData?.date_creation}.
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
